<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card>
            <v-toolbar flat dark dense color="accent">
              <v-toolbar-title
                >Gift Vouchers -
                {{ voucher.order.customer.full_name }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                x-small
                color="blue lighten-4 blue--text"
                class="mr-2"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn depressed x-small color="red lighten-4 red--text">
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </v-toolbar>

            <v-simple-table class="mb-4">
              <tbody>
                <tr>
                  <th>Order ID</th>
                  <th>Customer</th>
                  <th>Expiry Date</th>
                  <th>Value</th>
                  <th>Value Remaining</th>
                  <th>Total</th>
                </tr>
                <tr>
                  <td>{{ voucher.order_id }}</td>
                  <td>{{ voucher.customer.full_name }}</td>
                  <td>{{ voucher.expiry_date }}</td>
                  <td>{{ voucher.value }}</td>
                  <td>{{ voucher.value_remaining }}</td>
                  <td>£50.00</td>
                </tr>
              </tbody>
            </v-simple-table>

            <v-row>
              <v-col cols="2">
                Message<br />
                {{ voucher.order.message }}
              </v-col>
              <v-col cols="5">
                <v-simple-table>
                  <tbody>
                    <tr>
                      <th></th>
                      <td></td>
                    </tr>
                    <tr>
                      <th>Full Name</th>
                      <td>{{ voucher.order.customer.full_name }}</td>
                    </tr>
                    <tr>
                      <th>Address</th>
                      <td>
                        {{ voucher.customer.shipping_address.line_1 }}<br />
                        {{ voucher.customer.shipping_address.line_2 }}
                        {{ voucher.customer.shipping_address.city }}<br />
                        {{ voucher.customer.shipping_address.postcode }}
                      </td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>{{ voucher.customer.email }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
              <v-col cols="5">
                <v-simple-table>
                  <tbody>
                    <tr>
                      <th></th>
                      <td></td>
                    </tr>
                    <tr>
                      <th>Full Name</th>
                      <td>{{ voucher.customer.full_name }}</td>
                    </tr>
                    <tr>
                      <th>Address</th>
                      <td>
                        {{ voucher.customer.shipping_address.line_1 }}<br />
                        {{ voucher.customer.shipping_address.line_2 }}
                        {{ voucher.customer.shipping_address.city }}<br />
                        {{ voucher.customer.shipping_address.postcode }}
                      </td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>{{ voucher.customer.email }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: "Vouchers",
          disabled: false,
          to: { name: "module-arnprior-vouchers" },
          exact: true,
        },
        {
          text: "Voucher",
          disabled: true,
        },
      ],
    };
  },

  computed: {
    voucher() {
      return this.$store.getters["arnprior/vouchersStore/get"];
    },
  },
};
</script>
